<template>
<div>
  <CCard>
    <CCardHeader>
      <h4 style="color:#00A8FF">{{ device.name }}({{ serviceModel }})</h4>
    </CCardHeader>

    <CCardBody>
        <CCol col="12" xl="12">
          <ul class="nav nav-tabs nav-pills">
            <li class="nav-item">
              <a tab class="nav-link" @click.prevent="setActive('tab3')" :class="{ active: isActive('tab3') }" href='#tab3'>기기 상태</a>
            </li>        
            <li class="nav-item">
              <a tab class="nav-link" @click.prevent="setActive('tab4')" :class="{ active: isActive('tab4') }" href='#tab4'>이벤트 사진</a>
            </li>
            <li class="nav-item ">
              <a tab class="nav-link" @click.prevent="setActive('tab5')" :class="{ active: isActive('tab5') }" href='#tab5'>그래프 보기</a>
            </li>
            <li class="nav-item">
              <a tab class="nav-link" @click.prevent="setActive('tab6')" :class="{ active: isActive('tab6') }" href='#tab6'>일보 보기</a>
            </li>        
            <li class="nav-item">
              <a tab class="nav-link" @click.prevent="setActive('tab7')" :class="{ active: isActive('tab7') }" href='#tab7'>제품 정보</a>
            </li>                
          </ul>
        </CCol>   
     <div class="tab-content">
        <div class="tab-pane" id='tab3' :class="{ 'active show': isActive('tab3') }">
          <CCard>
            <CCardBody>
              <h5 style="margin-top: 20px; text-align: right;">{{measureDate}}</h5>
              <div class="deviceInfoBox">
              </div>
      
              <div class="deviceSlideBox" style="height: 45%;margin-right:30px;">
                <img :src="device_image" alt="기기 이미지" style="max-width: 100%; height: 100%;">
              </div>

              <div class="deviceInfoBox" style="text-align: right;">
                <div class="btnBox">
                  <button disabled="disabled" style="cursor: default">기기 작동상태</button>
                </div>
                <table>
                  <tr>
                    <th>시수공급 밸브</th>
                    <td style="font-weight: bold">{{runningWaterInValveStatus}}</td>
                  </tr>
                  <tr>
                    <th>SUCTION 밸브</th>
                    <td style="font-weight: bold">{{runningSuctionValveStatus}}</td>
                  </tr>
                  <tr>
                    <th>가압 펌프</th>
                    <td style="font-weight: bold">{{runningPumpStatus}}</td>
                  </tr>
                  <tr>
                    <th>정량 펌프</th>
                    <td style="font-weight: bold">{{runningMeterPumpStatus}}</td>
                  </tr>  
                </table>
              </div>

              <div class="deviceInfoBox" style="text-align: right;">
              <div class="btnBox">
                <button disabled="disabled" style="cursor: default">센서 정보</button>
              </div>
              <table>
                <tr>
                  <th>염소 잔량</th>
                  <td>{{waterLevelIn}}</td>
                </tr>                                           
              </table>
            </div>

            </CCardBody>
            <CCardFooter col="12" xl="12">
              <CButton color="danger" class="float-right" v-show="is_admin == true" @click="resetSensorBoard()">센서보드 리셋</CButton>
              <!--<CButton color="info" class="float-right mr-1" v-show="is_admin == true" @click="sensorMappingSetting()">수위센서 설정</CButton>-->
              <CButton color="primary" class="float-right mr-1" @click="autoPicSetting()">자동 촬영</CButton>
              <CButton color="success" class="float-right mr-1"  @click="openSettings('semi')">원격 제어</CButton>
              <CButton color="warning" class="float-right mr-1" @click="openDialog('sensor')">센서 정보 관리</CButton>
            </CCardFooter>
          </CCard>
        </div>
        <div class="tab-pane" id='tab4' :class="{ 'active show': isActive('tab4') }">
          <Camera
              ref="cameraView"
              :device_guid.sync="device_guid"
              :device.sync="device"
          />
          <Event ref="eventView" :device_guid="device_guid"/>
        </div>
        <div class="tab-pane" id='tab5' :class="{ 'active show': isActive('tab5') }">
          <HistoryMeasure
              ref="historyMeasure"
              :device_guid.sync="device_guid"
              :device.sync="device"
          />
        </div>
        <div class="tab-pane" id='tab6' :class="{ 'active show': isActive('tab6') }">
          <HistoryDevice
              ref=historyDevice
              :device_guid.sync="device_guid"
              :device.sync="device"
          />
        </div>
        <div class="tab-pane" id='tab7' :class="{ 'active show': isActive('tab7') }">
          <Productinfo
              ref=productinfo
              :device_guid.sync="device_guid"
              :device.sync="device"
          />
        </div>
      </div>      
    </CCardBody>
  </CCard>

  <Confirm
      ref="confirmDialog"
      title="확인"
      @hide="hideModal"
      color="warning"
  />
  <DeviceInfo
      ref="deviceInfo"
      :isAdmin="is_admin"
      :isMobile="is_mobile"
      :isPeriodic="false"
      :device.sync="device"
      @hide="hideModal"
      @update="onUpdated"
  />
  <AutoCarmeraSet
      ref="autoCarmeraSet"
      :isAdmin="is_admin"
      :isMobile="is_mobile"
      :isPeriodic="false"
      :device.sync="device"
      @hide="hideModal"
      @update="onUpdated"
  />
  <SensorMappingSet
    ref="sensorMappingSet"
    :isAdmin="is_admin"
    :isMobile="is_mobile"
    :isPeriodic="false"
    :device.sync="device"
    @hide="hideModal"
    @update="onUpdated"
  />

  <RemoteDialog
    ref="remoteDialog"
  />
</div>
</template>

<script>

import Device from '@/views/btfs/Device'
import DeviceInfo from '@/views/device/DeviceInfo'
import AutoCarmeraSet from '@/views/btfs/AutoCameraSet'
import SensorMappingSet from '@/views/btfs/SensorMappingSet'
import RemoteDialog from '@/views/watek/RemoteDialog';

import Event from '@/views/device/Event'
import Camera from '@/views/watek/Camera';
import Search from '@/views/device/Search';
import Productinfo from '@/views/watek/Productinfo';

import HistoryMeasure from '@/views/device/HistoryMeasure';
import HistoryDevice from '@/views/device/HistoryDevice';

const NO_MEASURE_COMENT = '측정값 없음'
const RUNNING_STATUS = 30;
const WATER_LEVEL = 33;

export default {
  name: 'WatekDevice',
  extends: Device,
  components: {
    RemoteDialog,
    DeviceInfo,
    AutoCarmeraSet,
    SensorMappingSet,
    Camera,
    Event,
    Search,
    Productinfo,
    HistoryMeasure,
    HistoryDevice
  },
  data () {
    return {
      activeItem: 'tab3',
    }
  },
  computed: {
    runningWaterInValveStatus(){
      if (_.isUndefined(this.device.sensor))
        return NO_MEASURE_COMENT      
      else
        return this.getValue(RUNNING_STATUS, 1)
    },
    runningSuctionValveStatus(){
      if (_.isUndefined(this.device.sensor))
        return NO_MEASURE_COMENT      
      else
        return this.getValue(RUNNING_STATUS, 2)
    },
    runningPumpStatus(){
      if (_.isUndefined(this.device.sensor))
        return NO_MEASURE_COMENT      
      else
        return this.getValue(RUNNING_STATUS, 3)
    },
    runningMeterPumpStatus(){
      if (_.isUndefined(this.device.sensor))
        return NO_MEASURE_COMENT      
      else
        return this.getValue(RUNNING_STATUS, 4)
    },
    waterLevelIn() {
      if (_.isUndefined(this.device.sensor))
        return NO_MEASURE_COMENT
      else
        return this.getValue(WATER_LEVEL, 1)
    },
  },
  methods: {
    getDataControl() {
      var self = this;
      var service_type = _.get(this.device, "service_type"); // || submerged
      switch (service_type) {
        case 'watek': this.serviceModel = '염소투입기'; break;
        default: this.serviceModel = '정수장치'
      }

      if (service_type == 'watek') {
        this.device_image = 'images/WT-100.jpg';
      } else {
        this.device_image = this.device.default_image;
      }
    },
  	isActive (menuItem) {
      return this.activeItem === menuItem
    },
    setActive (menuItem) {
      this.activeItem = menuItem
    },
    getDates() {
      if (this.activeItem === 'tab5')
        return this.$refs.historyMeasure.getDates();
      else
        return this.$refs.historyDevice.getDates();
    },   
    openSettings(which) {
      console.log('RemoteControl::openSettings which:', which)
      this.$refs.remoteDialog.show(which, this.device);
    },
    getValue(type, id) {
      var item = _.find(this.device.sensor.data, {type:type, id:id })
      if (item) {
        var stype = _.find(this.sensor_type, {code:item.type, id:item.id})
        if (!stype) stype = _.find(this.sensor_type, {code:item.type})
        if (stype) {
          if(item.type == RUNNING_STATUS){
            if(item.id == 1)
              return item.value >= 1 ? "열림" : "닫힘";
            else if(item.id == 2)
              return item.value >= 1 ? "열림" : "닫힘";              
            else if(item.id == 3)
              return item.value >= 1 ? "가동중" : "정지";     
            else if(item.id == 4)
              return item.value >= 1 ? "가동중" : "정지";                            
          }          
          return item.value.toFixed(2) + stype.unit
        } else {
          return NO_MEASURE_COMENT
        }
      } else {
        return NO_MEASURE_COMENT
      }
    },    
  }
}
</script>
