<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardBody col="12" xl="12">
          <CRow>
            <div class="col-sm-3 col-lg-3"></div>
            <div class="col-sm-6 col-lg-6" v-for="(view, i) in screens">

              <div v-if="!view.deviceImages.length">
                <div class="text-center border p-5">
                  <h4>측정된 이미지 파일 없음.<br/></h4>
                </div>
              </div>
              <div :id="'device-img' + view.direction" class="carousel slide border px-2" data-ride="carousel">
                <ol class="carousel-indicators">
                  <li data-target="#device-img" :data-slide-to="index" v-for="(img, index) in view.deviceImages" :class='{active:index==0}'></li>
                </ol>
                <div class="carousel-inner">
                  <div class="carousel-item" v-for="(img, index) in view.deviceImages" :class='{active:index==0}'>
                    <img class="d-block w-100" :src="img.url" @click="openDialog(img)" alt="">
                    <div class="carousel-caption">
                      <p>{{img.label}}<br>{{img.created_at}}</p>
                    </div>
                  </div>
                </div>
                <a class="carousel-control-prev" :href="'#device-img' + view.direction" role="button" data-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" :href="'#device-img' + view.direction" role="button" data-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="sr-only">Next</span>
                </a>
              </div>

              <div class="row-margin"></div>

              <div class="col-sm-6 col-lg-6" style="float:none;margin:auto;">
                <CButtonToolbar justify="center">
                  <CButton color="info" @click="takePicture(view.direction)">사진 촬영</CButton><p></p>
                </CButtonToolbar>
              </div>

            </div>
            <div class="col-sm-3 col-lg-3"></div>
          </CRow>
        </CCardBody>
      </CCard>

    </CCol>

    <ImageDialog ref="imageDialog"/>

    <Notify ref="notifyDialog"/>
  </CRow>
</template>

<script>
import loopback from '@/services/loopback';
import moment from 'moment';
import Camera from '@/views/btfs/Camera'

export default {
  name: 'WatekCamera',
  extends: Camera,
  data () {
    return {
      screens: [
        {
          title: '이벤트 사진',
          direction: 'left',
          deviceImages: [],
        }
      ]
    }
  },
  methods: {
    getDeviceImages() {
      var self = this
      this.screens.forEach(view => {
        view.deviceImages = [];
      })

      var filter = {
          where: {
            and: [{
                device_guid: this.device_guid
              },
            ]
          },
          order: 'created_at desc',
          limit: 10,
      }

      this.$store.dispatch('rest/find', {model:'photos',filter:filter})
        .then(function(response) {
          var pushImage = function(info) {
            var path = '{0}/{1}s/{2}/download/{3}'.format(loopback.defaults.baseURL, info.storage, self.device_guid, info.file_name)
            var index = info.flow_type === 'in' ? 0 : 1;
            self.screens[index].deviceImages.push({
              url: path,
              created_at: moment(info.created_at).format('YYYY년 MM월 DD일 HH시 mm분 ss초')
            })
          }
          for (var i = 0; i < response.length; i++) {
            var info = response[i]
            if (_.isUndefined(info.storage)) continue;
            // if (_.isUndefined(info.file.crop)) continue;
            pushImage(info);
          }
        })
    },
    takePicture(direction) {
      console.log('Camera::takePicture dicrection: ', direction)

      var data = {
        command: 'exec',
        device_guid: this.device_guid,
        params: [{
          cmd: 'shot',
          type: direction.substring(0,2),
          data: "shotimage.jpg"
        }]
      }
      // console.log('Camera::takePicture params:', data)
      this.$store.dispatch('rest/method', {model:'devices',method:'Command',data:data})
        .then(res => {
          console.log('Camera::takePicture res: ', res)
        })
        .catch(err => {
          console.log('Camera::takePicture error: ', err.toString())
          var message = err.toString();
          if(message === "Error: Already running command: run after a minutes"){
            message = "이미 처리중인 작업이 있습니다. 잠시만 기다려주세요.";
          }
          this.$refs.notifyDialog.show(message.toString())
        })
    },
  }
}
</script>