<template>
<div>
  <CCard>
    <CCardHeader>
    </CCardHeader>

    <CCardBody>
      <CRow>
      <div class="col-sm-3 col-lg-1">
      </div>
        <div class="col-sm-12 col-lg-4">
          <div id="device-img" class="carousel slide carousel-fade" data-ride="carousel" data-interval="3000">
            <ol class="carousel-indicators">
              <li data-target="#device-img" :data-slide-to="index" v-for="(img, index) in deviceImages" :class='{active:index==0}'></li>
            </ol>
            <div class="carousel-inner">
              <div class="carousel-item" v-for="(img, index) in deviceImages" :class='{active:index==0}'>
                <img class="d-block w-100" :src="img.url" :style="image_style" @click="openUploadDialog()" alt="">
                <div class="carousel-caption">
                  <p>{{img.label}}<br>{{img.created_at}}</p>
                </div>
              </div>
            </div>
            <a class="carousel-control-prev" href="#device-img" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>
            <a class="carousel-control-next" href="#device-img" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
          </div>
        </div>

        <div class="col-sm-12 col-lg-6">
          <div class='table'>
            <v-table id="deviceTable" :columns="columns" :data="data" :options="options" style="height: 100%;"></v-table>
          </div>
        </div>
      <div class="col-sm-3 col-lg-1">
      </div>
      </CRow>
    </CCardBody>
    <CCardFooter col="12" xl="12">
    </CCardFooter>
  </CCard>

    <Uploader
      id="imageUploader"
      ref="uploaderDialog"
      :device_guid="device_guid"
      :storage.sync="storage"
      @hide="hideModal"
  />
</div>

</template>

<script>
import Device from "@/views/device/Device"

export default {
  name: 'WatekProductinfo',
  extends: Device,
}
</script>
