const REMOTE_CONTROL_TABLE_OPTIOINS = {
    rowStyle: function(row, index) {
        return {
            classes: 'none'
    };
    },
    formatNoMatches: function() {
        return '-';
    },
    cache: false,
    striped: false,
    bordered: false,
    pagination: false,
    search: false,
    showColumns: false,
    showRefresh: false,
    minimumCountColumns: 2,
    clickToSelect: false,
    singleSelect: false,
    showToggle: false
}

const REMOTE_CONTROL_TABLE_COLUMS = [
    {
        field: 'item',
        title: '항목',
        align: 'center',
        valign: 'top',
        sortable: false,
    }, {
        field: 'time',
        title: '시간(초)',
        align: 'center',
        valign: 'top',
        sortable: false
    }, {
        field: 'enable',
        title: '설정',
        align: 'center',
        valign: 'top',
        sortable: false,
    }
]

const REMOTE_CONTROL_TYPE = {
    'watek' : [
        {
            item: '시수공급 밸브',
            field: 'valve1',
            time: 10,
            enable: false
        }, {
            item: 'SUCTION 밸브',
            field: 'valve2',
            time: 10,
            enable: false
        }, {
            item: '가압 펌프',
            field: 'pump',
            time: 10,
            enable: false
        }, {
            item: '정량 펌프',
            field: 'meter',
            time: 10,
            enable: false
        }
    ]
}

export {
    REMOTE_CONTROL_TABLE_OPTIOINS,
    REMOTE_CONTROL_TABLE_COLUMS,
    REMOTE_CONTROL_TYPE
}